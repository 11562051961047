<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <span
          :style="[isShown(item.id) ? { display: 'none' } : {}]"
          :key="item.id"
        >
          <li class="menu-title" v-if="item.isTitle" :key="item.id">
            {{ $t(item.label) }}
          </li>
          <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
            <a
              v-if="hasItems(item)"
              href="javascript:void(0);"
              class="is-parent"
              :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            >
              <i
                style="color: white; opacity: 0.5"
                :class="`bx ${item.icon}`"
                v-if="item.icon"
              ></i>
              <span class="d-none d-lg-block">{{ $t(item.label) }}</span>
              <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge"
                >{{ $t(item.badge.text) }}</span
              >
            </a>

            <router-link
              :to="item.link"
              v-if="!hasItems(item)"
              class="side-nav-link-ref"
            >
              <i
                style="color: white; opacity: 0.5"
                :class="`bx ${item.icon}`"
                v-if="item.icon"
              ></i>
              <span class="d-none d-lg-block">{{ $t(item.label) }}</span>
              <span
                :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                v-if="item.badge"
                >{{ $t(item.badge.text) }}</span
              >
            </router-link>

            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li v-for="(subitem, index) of item.subItems" :key="index">
                <span
                  :style="[
                    isShown(subitem.id, subitem.parentId)
                      ? { display: 'none' }
                      : {},
                  ]"
                  :key="item.id"
                >
                  <router-link
                    :to="getLinkRedirect(subitem)"
                    v-if="!hasItems(subitem)"
                    class="side-nav-link-ref"
                    >{{ $t(subitem.label) }}</router-link
                  >
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    href="javascript:void(0);"
                    >{{ $t(subitem.label) }}</a
                  >
                  <ul
                    v-if="hasItems(subitem)"
                    class="sub-menu mm-collapse"
                    aria-expanded="false"
                  >
                    <li
                      v-for="(subSubitem, index) of subitem.subItems"
                      :key="index"
                    >
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                        >{{ $t(subSubitem.label) }}</router-link
                      >
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </li>
        </span>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapActions, mapGetters } from "vuex";

export default {
  created: async function () {
    await this.checkLoggedUserRolePermission({ permissionId: 18 });
    await this.checkLoggedUserRolePermission({ permissionId: 17 });
    await this.checkLoggedUserRolePermission({ permissionId: 13 });
    await this.checkLoggedUserRolePermission({ permissionId: 12 });
    await this.checkLoggedUserRolePermission({ permissionId: 10 });
    await this.checkLoggedUserRolePermission({ permissionId: 8 });
    await this.checkLoggedUserRolePermission({ permissionId: 9 });
    await this.checkLoggedUserRolePermission({ permissionId: 7 });
    if (this.selectedVenueId) {
      await this.checkLoggedUserRolePermission({
        permissionId: 6,
        venueId: this.selectedVenueId,
      });
    }
  },

  mounted: function () {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters("layoutModule", {
      menuItems: "GET_MENU_ITEMS",
    }),
    ...mapGetters("userModule", {
      hasViewListOfUsersPermissions: "GET_LIST_OF_USERS_PERMISSION",
      hasRoleManagementPermissions: "GET_ROLE_MANAGEMENT_PERMISSION",
      hasViewInvoicesPermissions: "GET_INVOICES_PERMISSION",
      hasEditSubscripitonPermissions: "GET_EDIT_SUBSCRIPTION_PERMISSION",
      hasPaymentMethodPermissions: "GET_PAYMENT_METHOD_PERMISSION",
      hasAppFeaturesSetupPermissions: "GET_APP_FEATURES_PERMISSION",
      hasViewAudioCampaignsPermission: "GET_VIEW_AUDIO_CAMPAIGNS_PERMISSION",
      hasSendMessagesPermission: "GET_SEND_MESSAGES_PERMISSION",
      hasVenueSurveyPermission: "GET_VENUE_SURVEY_PERMISSION",
      isUserSuperAdmin: "IS_USER_SUPER_ADMIN",
    }),
    ...mapGetters("venueModule", {
      selectedVenue: "GET_SELECTED_VENUE",
      selectedVenueId: "GET_SELECTED_VENUE_ID",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      isCorporate: "GET_IS_CORPORATE",
    }),
  },

  methods: {
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION",
    }),
    /**
     * Returns true or false if given menu item has child or not
     * @param item menu
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    isShown(itemId, parentId = null) {
      if (
        itemId === 7 &&
        !parentId &&
        !this.hasViewListOfUsersPermissions &&
        !this.hasRoleManagementPermissions
      ) {
        return true;
      } else if (
        // #3763 ukrytie Balik sluzieb & fakturacie pre corporate klientov
        itemId === 6 &&
        !parentId &&
        this.isCorporate &&
        !this.isUserSuperAdmin
      ) {
        return true;
      } else if (
        itemId === 6 &&
        !parentId &&
        !this.hasPaymentMethodPermissions &&
        !this.hasViewInvoicesPermissions &&
        !this.hasEditSubscripitonPermissions &&
        !this.isUserSuperAdmin // pridane, pretoze povodna logika prepisovala menu v adminzone
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 7 &&
        parentId === 7 &&
        !this.hasViewListOfUsersPermissions
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 8 &&
        parentId === 7 &&
        !this.hasRoleManagementPermissions
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 7 &&
        parentId === 6 &&
        !this.hasViewInvoicesPermissions
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 8 &&
        parentId === 6 &&
        !this.hasPaymentMethodPermissions
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 6 &&
        parentId === 5 &&
        !this.hasAppFeaturesSetupPermissions
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 6 &&
        (!this.hasEditSubscripitonPermissions ||
          !this.hasPaymentMethodPermissions)
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 8 &&
        parentId === 8 &&
        !this.hasViewAudioCampaignsPermission
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 9 &&
        parentId === 8 &&
        !this.hasSendMessagesPermission
      ) {
        return true;
      } else if (
        parentId &&
        itemId === 10 &&
        parentId === 8 &&
        !this.hasVenueSurveyPermission
      ) {
        return true;
      } else if (
        !parentId &&
        itemId === 8 &&
        !this.hasVenueSurveyPermission &&
        !this.hasSendMessagesPermission &&
        !this.hasViewAudioCampaignsPermission
      ) {
        return true;
      } else {
        return false;
      }
    },
    getLinkRedirect(subitem) {
      if (subitem.id === 6 && subitem.parentId === 5) {
        return `${subitem.link}${this.selectedVenueId}`;
      } else {
        return subitem.link;
      }
    },
  },
  watch: {
    companyId: {
      async handler(value) {
        await this.checkLoggedUserRolePermission({ permissionId: 18 });
        await this.checkLoggedUserRolePermission({ permissionId: 17 });
        await this.checkLoggedUserRolePermission({ permissionId: 13 });
        await this.checkLoggedUserRolePermission({ permissionId: 12 });
        await this.checkLoggedUserRolePermission({ permissionId: 10 });
        await this.checkLoggedUserRolePermission({ permissionId: 8 });
        await this.checkLoggedUserRolePermission({ permissionId: 9 });
        if (this.selectedVenueId) {
          await this.checkLoggedUserRolePermission({
            permissionId: 6,
            venueId: this.selectedVenueId,
          });
        }
      },
    },
  },
};
</script>
