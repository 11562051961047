













import Vue, {PropType} from "vue";
import {CompanyData} from "@/types";
import {mapActions} from "vuex";

export default Vue.extend({
  name: "nav-bar-active-company-card" as string,

  props: {
    selectedCompany: Object as PropType<CompanyData>
  },

  methods: {
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    openChooseCompanyModal(): void {
      this.$bvModal.show("choose-company-modal");
    },
    insertCompanyImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    }
  },

  watch: {
    selectedCompany: {
      handler(value: CompanyData): void {
        if (!!value.image_last_updated) {
          this.setImage({id: value.id, imageType: "venue_owner"});
        }
      },
      deep: true
    }
  }
})
