






























import Vue, {PropType} from 'vue';
import {CompanyData} from "@/types";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "choose-company-modal" as string,

  props: {
    companies: Array as PropType<Array<CompanyData>>
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    })
  },

  methods: {
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY"
    }),
    insertCompanyImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    async selectCompany(companyId: number): Promise<void> {
      if (companyId !== this.companyId) await this.setCompany(companyId);
      localStorage.setItem("isPlaying", "false");
      this.$bvModal.hide("choose-company-modal");
    }
  }
});
