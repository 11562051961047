

























import Vue from "vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "nav-bar-user-dropdown" as string,

  computed: {
    ...mapGetters('userModule', {
      user: "GET_LOGGED_USER",
      userImage: "GET_USER_IMAGE",
      isUserSuperAdmin: "IS_USER_SUPER_ADMIN"
    }),
    userName(): string {
      return this.user.first_name + " " + this.user.last_name;
    },
    insertUserImage(): string {
      if (!this.userImage) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.userImage}`;
    }
  },

  methods: {
    ...mapActions('userModule', {
      logoutUser: 'LOGOUT'
    }),
    logout(): void {
      localStorage.removeItem('trial_dialog_showed');
      localStorage.removeItem('init_play');
      this.logoutUser();
    }
  }
})
