












import Vue from 'vue';
import Vertical from './vertical.vue';
import Horizontal from './horizontal.vue';
import { mapGetters } from "vuex";

export default Vue.extend({
  components: { Vertical, Horizontal },
  data() {
    return {};
  },
  computed: {
      ...mapGetters('layoutModule', {
        layoutType: 'layoutType'
      })
  },
  methods: {},
});
