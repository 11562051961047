



































import Vue, {PropType} from "vue";
import {mapActions} from "vuex";
import {VenueSubscriptionData} from "@/types";

export default Vue.extend({
  name: "trial-modal" as string,
  props: {
    venue: {
      type: Object as PropType<VenueSubscriptionData> | null,
    },
    modalId: {
      type: String as PropType<String>,
    }
  },
  computed: {
    trialExpiredDescription(): string {
      if (this.venue === null) {
        return '';
      }
      
      let endDate = new Date(0);
      endDate.setUTCSeconds(this.venue.subscriptionEndsAt);

      const venueName: string = this.venue.venueName;
      const dayValue: string = endDate.getDate().toString() + '.' +
        (endDate.getMonth() + 1).toString() + '.' +
        endDate.getFullYear().toString();

      return this.$t("The trial period has ended on") +
        dayValue +
        this.$t("for venue") +
        venueName +
        this.$t("To continue using Songoroo Smart Music, please select one of our subscriptions plans.");
    },
    contactUsLabel(): string {
      return this.$t("Contact us").toUpperCase();
    },
    selectSubscriptionLabel(): string {
      return this.$t("Select subscription plan").toUpperCase();
    }
  },
  methods: {
    ...mapActions("venueModule", {
      setVenueRegisterData: "SET_VENUE_REGISTER_DATA",
    }),
    navigateToSubscriptions(): void {
      if (this.venue === null) {
        return;
      }

      this.$router.push('/subscription-plan/renew/' + this.venue.venueId);
    },
    navigateToContacts(): void {
      this.$router.push('/support');
    }
  }
})
