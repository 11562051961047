





































import Vue from 'vue';
import HorizontalTopbar from "@/components/horizontal-topbar.vue";
import HorizontalNav from "@/components/horizontal-nav.vue";
// import RightBar from "@/components/right-bar.vue";
import {mapGetters} from "vuex";
// import { layoutComputed } from "@/state/helpers";

export default Vue.extend({
  components: {
    HorizontalTopbar,
    HorizontalNav,
    // RightBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('layoutModule', {
        topBar: 'topBar',
        layoutWidth: 'layoutWidth',
        loader: 'loader'
    })
    // ...layoutComputed,
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-layout-size");
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
});
