


































import Vue from 'vue';
import router from "@/router";
// import { layoutComputed } from "@/state/helpers";
import NavBar from "@/components/nav-bar.vue";
import SideBar from "@/components/side-bar.vue";
// import RightBar from "@/components/right-bar.vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  components: { NavBar, SideBar },
  data() {
    return {
      isMenuCondensed: false
    }
  },

  computed: {
    ...mapGetters('layoutModule', {
      topBar: 'topBar',
      layoutWidth: 'layoutWidth',
      loader: 'loader',
      leftSidebarType: 'leftSidebarType'
    })
    // ...layoutComputed,
  },
  created: () => {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
    document.body.removeAttribute("data-layout-size");
  },
  methods: {
    ...mapActions("layoutModule", {
      toggleMenuState: "SET_TOGGLE_MENU_STATE"
    }),
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (!document.body.classList.contains("sidebar-enable")) {
        document.body.classList.add("hidden-sidebar-helper");
      } else {
        document.body.classList.remove("hidden-sidebar-helper");
      }

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
      this.isMenuCondensed = !this.isMenuCondensed;
      this.toggleMenuState(this.isMenuCondensed);
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
});
