









































import Vue from "vue";
import simpleBar from "simplebar-vue";
import NavBarNotificationsDropdownItem from "@/components/nav-bar-notifications-dropdown-item.vue";
import { mapActions, mapGetters } from "vuex";
import {CZUserNotificationData} from "@/types";

export default Vue.extend({
  name: "nav-bar-notifications-dropdown" as string,

  components: {
    "nav-bar-notifications-dropdown-item" :NavBarNotificationsDropdownItem,
    simpleBar
  },

  data() {
    return {
      notificationsLimit: 2,
      showLoadMoreBtn: true
    }
  },

  computed: {
    ...mapGetters("notificationModule", {
      notifications: "GET_CZ_USER_NOTIFICATIONS",
      notificationsLength: "GET_UNCHECKED_NOTIFICATIONS_LENGTH"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    limitedNotifications(): Array<CZUserNotificationData> {
      return this.notificationsLimit ? this.notifications.slice(0, this.notificationsLimit) : this.notifications;
    },
    uncheckedNotificationsLength(): number {
      return this.notifications.filter(n => !n.wasInspected).length;
    }
  },

  methods: {
    ...mapActions("notificationModule", {
      fetchNotificationsLength: "FETCH_UNCHECKED_NOTIFICATIONS_LENGTH"
    }),
    ...mapActions("notificationModule", {
      setUserNotifications: "FETCH_CZ_USER_NOTIFICATIONS"
    }),
    showAllDropdownNotifications(): void {
      this.notificationsLimit = null;
      this.showLoadMoreBtn = false;
    }
  },
  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void>{
        if (newValue != oldValue){
          this.fetchNotificationsLength()
          this.setUserNotifications()
        }
      }
    }
  }
})
