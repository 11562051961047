

















import Vue, {PropType} from "vue";
import { CZUserNotificationData } from "@/types";
import {notificationsTypesRoutes, NotificationTypeRoute} from "@/utils/notifications-types-routes";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "nav-bar-notifications-dropdown-item" as string,

  props: {
    data: Object as PropType<CZUserNotificationData>
  },

  data() {
    return {
      notificationsRoutes: notificationsTypesRoutes
    }
  },

  computed: {
    ...mapGetters("notificationModule", {
      CZUserNotifications: "GET_CZ_USER_NOTIFICATIONS"
    }),
    locale(){
      return i18n.locale
    },
    notificationName(): string {
      return this.locale === "en" ? this.data.clientZoneNotification.clientZoneNotificationNameEn : this.data.clientZoneNotification.clientZoneNotificationNameSk;
    },
    notificationDescription(): string {
      return this.locale === "en" ? this.data.clientZoneNotification.clientZoneNotificationDescriptionEn : this.data.clientZoneNotification.clientZoneNotificationDescriptionSk;
    },
    notificationCreatedAt(): string {
      var a = new Date(this.data.createdAt * 1000);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = "0" +  a.getMinutes();
      var sec = "0" +  a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min.substr(-2) + ':' + sec.substr(-2) ;
      return time;
    },
    notificationRoute(): string {
      const notificationRoute: NotificationTypeRoute = this.notificationsRoutes.find(n => n.type === this.data.clientZoneNotification.clientZoneNotificationType);

      if (this.data.clientZoneNotification.clientZoneNotificationType === 7 || this.data.clientZoneNotification.clientZoneNotificationType === 8) {
        return notificationRoute.path + `/${this.data.venueId}`;
      }
      if(this.data.clientZoneNotification.clientZoneNotificationType === 13){
        return notificationRoute.path + `/${this.data.targetEntityId}`
      }

      return notificationRoute.path;
    }
  },

  methods: {
    ...mapActions("notificationModule", {
      setNotificationAsInspected: "SET_NOTIFICATION_AS_INSPECTED"
    }),
    goToNotificationPage(): void {
      if (!this.data.wasInspected) {
        this.setNotificationAsInspected(this.data.id);
      }

      if (router.currentRoute.path !== this.notificationRoute) {
        this.$router.push(this.notificationRoute);
      }
    }
  }
})
