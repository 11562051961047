

































































import Vue from 'vue';
import i18n from "../i18n/i18n";
import {mapActions, mapGetters} from "vuex";
import {CompanyData, VenueSubscriptionData} from "@/types";
import NavBarNotificationsDropdown from "@/components/nav-bar-notifications-dropdown.vue";
import NavBarActiveCompanyCard from "@/components/NavBarActiveCompanyCard.vue";
import ChooseCompanyModal from "@/components/modals/ChooseCompanyModal.vue";
import NavBarUserDropdown from "@/components/nav-bar-user-dropdown.vue";
import TrialModal from "@/components/modals/TrialModal.vue";


export default Vue.extend({
  components: {
    "nav-bar-notifications-dropdown": NavBarNotificationsDropdown,
    "nav-bar-active-company-card": NavBarActiveCompanyCard,
    "choose-company-modal": ChooseCompanyModal,
    "nav-bar-user-dropdown": NavBarUserDropdown,
    "trial-modal": TrialModal,
  },

  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/en.png"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/sk.png"),
          language: "sk",
          title: "Slovenčina"
        },
        {
          flag: require("@/assets/images/flags/cs.png"),
          language: "cs",
          title: "Čeština"
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      trialVenue: null,
    };
  },

  async mounted(): Promise<void> {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;

    if (!this.isUserSuperAdmin) {
      await this.setCompanies();
      this.fetchCompaniesImages();
      if (!this.venues.length) {
        await this.setVenues({fetchRatings: false, name: ''});
        await this.setVenuesSubscription();
      }
      if (!!this.user.image_last_updated) {
        await this.setImage({id: this.user.id, imageType: "clientzone_user"});
      }
      this.openTrialModalIfVenueSubscriptionEnds();
      await this.setUserNotifications();
    }
  },

  computed: {
    ...mapGetters('userModule', {
      getUserData: 'GET_USER_DATA',
      isUserImage: "IS_USER_IMAGE",
      user: "GET_LOGGED_USER",
      isUserSuperAdmin: "IS_USER_SUPER_ADMIN",
    }),
    ...mapGetters('companyModule', {
      selectedCompany: 'GET_COMPANY',
      companies: "GET_COMPANIES"
    }),
    ...mapGetters("venueModule", {
      selectedVenueId: "GET_SELECTED_VENUE_ID",
      venues: "GET_VENUES"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
    }),
    getCompanies(): Array<object> {
      return this.getUserData.companies
    }
  },

  methods: {
    ...mapActions('companyModule', {
      setCompanies: 'FETCH_COMPANIES',
      setCompany: 'SET_COMPANY'
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES",
    }),
    ...mapActions("notificationModule", {
      setUserNotifications: "FETCH_CZ_USER_NOTIFICATIONS"
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
    }),
    fetchCompaniesImages(): void {
      for (let i = 0; i < this.companies.length; i++) {
        const company: CompanyData = this.companies[i];

        if (!!company.image_last_updated) {
          this.setImage({id: company.id, imageType: "venue_owner", multi: true});
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document['fullscreenElement'] &&
        /* alternative standard method */ !document['mozFullScreenElement'] &&
        !document['webkitFullscreenElement']
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement['mozRequestFullScreen']) {
          document.documentElement['mozRequestFullScreen']();
        } else if (document.documentElement['webkitRequestFullscreen']) {
          document.documentElement['webkitRequestFullscreen'](
            //@ts-ignore
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document['cancelFullScreen']) {
          document['cancelFullScreen']();
        } else if (document['mozCancelFullScreen']) {
          document['mozCancelFullScreen']();
        } else if (document['webkitCancelFullScreen']) {
          document['webkitCancelFullScreen']();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("language", locale);
    },
    openTrialModalIfVenueSubscriptionEnds(): void {
      let wasSubsriptionTrialDialogShowed = false as boolean;
      let wasSubsriptionTrialDialogShowedValue = localStorage.getItem('trial_dialog_showed');

      if (wasSubsriptionTrialDialogShowedValue !== null) {
        wasSubsriptionTrialDialogShowed = !!wasSubsriptionTrialDialogShowedValue; 
      }

      if (wasSubsriptionTrialDialogShowed === true) {
        return;
      }

      this.venuesSubscription.map((venueSubscription: VenueSubscriptionData) => {
        let currentTimestamp = Math.floor(Date.now() / 1000) as number;
        let subscriptionEndTimestamp = parseInt(venueSubscription.subscriptionEndsAt) as number;

        if (venueSubscription.isTrial === true && currentTimestamp > subscriptionEndTimestamp) {
          localStorage.setItem('trial_dialog_showed', 'true');
          this.trialVenue = venueSubscription;
          this.$bvModal.show("trial-modal");
          return;
        }
      });
    },
  }
})
